<template>
   <!-- 订单列表 -->
   <div 
     class="storage" 
     v-loading.fullscreen.lock="loading" 
     element-loading-text="请求数据中" 
     element-loading-background="rgba(255, 255, 255, 0.5)"
   >
     <div class="append">
       <div class="seach">
        <ShopsAndSites
          v-if="tress.length != 0"
          style="margin-right: 10px"
          :list="tress"
          @getShopId="getID"
        ></ShopsAndSites>
         <el-select v-model="pageInfoList.orderType" placeholder="订单状态" style="margin-right: 20px;width:160px" clearable @change="search">
           <el-option
            v-for="(item,index) in orderTypeList"
            :key="index"
            :label="item"
            :value="item">
           </el-option>
         </el-select>
         <el-select v-model="pageInfoList.isTest" placeholder="是否测评" style="margin-right: 20px;width:160px" clearable @change="search">
           <el-option
            v-for="item in viewTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id">
           </el-option>
         </el-select>
         <el-select v-model="pageInfoList.dispatchType" placeholder="配送方式" style="margin-right: 20px;width:160px" clearable @change="search">
           <el-option
            v-for="(item, index) in dispatchTypeList"
            :key="index"
            :label="item"
            :value="item">
           </el-option>
         </el-select>
         <el-date-picker
           v-model="timeRange"
           type="daterange"
           range-separator="至"
           start-placeholder="开始日期"
           end-placeholder="结束日期"
           format="yyyy 年 MM 月 dd 日"
           value-format="yyyy-MM-dd"
           style="margin-right: 20px"
         ></el-date-picker>
         <el-input style="width:310px;" v-model="keyword" placeholder="请输入ASIN"  @keyup.enter.native = "search">
            <el-select 
              clearable 
              @clear="clearAsin" 
              v-model="select_Asin" 
              style="width: 120px" 
              placeholder="请选择类型"
              slot="prepend"
              @change="search"
              >
                <el-option
                 v-for="item in subAsinList"
                 :key="item.id"
                 :label="item.value"
                 :value="item.id"
                 :disabled="item.id == 'parent_asin'">
                </el-option>
            </el-select>
         </el-input>
         <el-input v-model="pageInfoList.amazonOrderNumber" style="width:160px" clearable placeholder="请输入订单号" @keyup.enter.native = "search"></el-input>
         <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
         <!-- 列表区域 -->
         <el-table
           border
           :data="tableDataList"
           style="width: 100%"
           :header-cell-style="{background:'#EFEFEF',textAlign:'center', height: '50px'}"
          >
            <el-table-column label="商品信息" align="center" width="270">
               <template slot-scope="scope">
                 <el-popover placement="right" width="520" trigger="hover" transition="fade-in-linear" close-delay="30">
                   <!-- 鼠标悬浮 -->
                   <div style="display:flex">
                      <div class="pic">
                        <span v-if="!scope.row.imageUrl">暂无图片</span>
                        <img v-else :src="scope.row.imageUrl" alt="" width="100%">
                      </div>
                      <div class="suspend" style="width:320px; font-size:14px">
                        <p style="margin-bottom:10px">
                          <span>
                            <img style="width:20px;margin:0 6px 0 0" :src="scope.row.marketPlaceImageUrl" alt=""/>
                            <p>子ASIN:{{scope.row.asin}}</p>
                          </span>
                          <span>父ASIN:{{scope.row.parentAsin}}</span>
                        </p>
                        <p><span>SKU:{{scope.row.commoditySku}}</span><span>品牌:{{scope.row.label}}</span></p>
                      </div>
                   </div>
                   <div class="goodsInfo" slot="reference">
                     <div class="noPic">
                       <span v-if="!scope.row.imageUrl">暂无图片</span>
                       <img v-else :src="scope.row.imageUrl" alt="" width="100%">
                     </div>
                     <div style="text-align:left">
                        <p>子ASIN:&nbsp;&nbsp;{{ scope.row.asin }}</p>
                        <p>SKU:&nbsp;&nbsp;{{ scope.row.commoditySku }}</p>
                     </div>
                   </div>
                 </el-popover>
               </template>
            </el-table-column>
            <el-table-column label="订单号" prop="orderNumber" align="center" width="245"></el-table-column>
            <el-table-column label="商品数量" prop="totalQuantity" align="center"></el-table-column>
            <el-table-column prop="salePrice" align="center">
               <template slot="header">
                 <span>销售额(¥)</span>
                 <el-tooltip effect="dark" placement="bottom">
                   <div slot="content">
                     <span>所指的是：<span style="color: #CD853F">商品的销售额（欧洲市场shipped状态订单已扣VAT），而非订单金额</span>,</span><br/>
                     <span>即不包含各项税费，运费、礼品费和扣除促销等相关费用的金额。</span><br/>
                     <span>计算逻辑：销售订单<span style="color: #CD853F">（不包括：取消、预售未付款授权 状态的订单）</span>中的商品单价 * 销售数量的金额总和。</span><br/>
                     <span>某一笔订单的销售额（商品销售额），就是这笔订单在亚马逊后台交易详情中的商品价格。</span><br/>
                     <span>① 销售订单的归属日期，以下单时间进行统计;</span><br/>
                     <span>② 如果订单发生退款，此订单会正常统计销售额，对应的退款金额会以退款时间，统计到当日总支出的退款项中;</span><br/>
                     <span>③ 部分pending订单，亚马逊一段时间内，返回的销售数据可能为0，此时系统会按最近成交的情况进行预算</span><br/>
                     <span style="color: #CD853F">预算数据项包括：销售额、亚马逊佣金、FBA费用）;</span>
                   </div>
                   <span class="el-icon-question"></span>
                 </el-tooltip>
               </template>
            </el-table-column>
            <el-table-column prop="orderTotal" align="center">
               <template slot="header">
                  <span>付款金额(¥)</span>
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      <span>买家实际支付金额的总和</span><br/>
                      <span style="color: #CD853F">（包括销售额、包装费、税费、运费等费用，但扣除促销金额）。</span><br/>
                      <span>特别说明：部分暂时无法获取支付金额的pending订单,</span><br/>
                      <span>此时系统会按最近成交的情况进行预算，预算的值=销售额（商品价格*购买数据）</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
               </template>
            </el-table-column>
            <el-table-column prop="orderCost" align="center">
               <template slot="header">
                  <span>订单成本(¥)</span>
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      <span>订单相关的支, 包括：FBA费用、亚马逊佣金、促销费用 、亚马逊其它费用、系统设置商品成本。</span><br/>
                      <span>1、FBA费用、亚马逊佣金、促销费用、亚马逊其它费用，是通过亚马逊接口返回;</span><br/>
                      <span>2、系统设置商品成本：包括商品的采购成本、物流成本（头程 / FBM）、其它费用，可在“库存管理 - 商品成本”中进行设置或者上传;</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
               </template>
            </el-table-column>
            <el-table-column label="配送方式" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.fulfillmentChannel == 'AFN'" style="color: #4169E1">{{scope.row.fulfillmentChannel}}</span>
                <span v-if="scope.row.fulfillmentChannel == 'MFN'" style="color: #228B22">{{scope.row.fulfillmentChannel}}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单状态" prop="orderStatus" align="center" width="120"></el-table-column>
            <el-table-column label="顾客姓名" prop="buyerName" align="center"></el-table-column>
            <el-table-column label="买家站内邮箱" prop="buyerEMail" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" width="220">
              <template slot="header">
                <span>创建时间</span><br/>
                <span>最晚发货时间</span>
              </template>
             <template slot-scope="scope">
               <span>{{scope.row.purchaseDate}}</span><br/>
               <span>{{scope.row.lastShipDate}}</span>
             </template>
            </el-table-column>
            <el-table-column label="备注" prop="systemMemo" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
               <template slot-scope="scope">
                  <el-link 
                    type="primary" 
                    :underline="false"
                    icon="el-icon-edit" 
                    style="margin-right: 8px"
                    @click="onEditBtn(scope.row)"
                  >编辑</el-link>
                  <el-link 
                    type="success"
                    :underline="false" 
                    @click="onShowDetail(scope.row)"
                  >详情
                   <i class="el-icon-view el-icon--right"></i>
                 </el-link>
               </template>
            </el-table-column>
         </el-table>
         <!-- 分页区域 -->
         <div class="Moder_pages">
           <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfoList.current"
            :page-sizes="[20, 30, 50, 100]"
            :page-size="pageInfoList.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
           </el-pagination>
         </div>
         <!-- 订单详情 -->
         <el-dialog
           width="60%"
           title="订单详情"
           :visible.sync="dialogVisible"
           :before-close="handleClose"
          >
            <el-row style="margin-bottom: 20px; font-size: 16px'; font-weight: bold">
              <span>订单号：{{ showOrderDetail.orderNumber }}</span>
            </el-row>
            <el-table
             border
             :data="orderDataList"
             :header-cell-style="{background:'#EFEFEF'}"
             >
               <el-table-column label="商品信息" prop="" align="center" width="220px">
                  <template slot-scope="scope">
                     <el-popover
                       placement="right"
                       width="450"
                       trigger="hover"
                      >
                        <div style="display:flex">
                            <div class="pic">
                                <span v-if="!scope.row.imageUrl">暂无图片</span>
                                <img v-else :src="scope.row.imageUrl" alt="" width="100%">
                            </div>
                            <div class="suspend" style="width:100px;font-size:14px">
                                <p><span>SKU:{{ scope.row.commoditySku }}</span></p>
                                <p style="margin-bottom:10px"><span>子ASIN:{{scope.row.asin}}</span></p>
                            </div>
                        </div>
                        <div class="goodsInfo" slot="reference">
                          <div class="noPic">
                             <span v-if="!scope.row.imageUrl">暂无图片</span>
                             <img v-else :src="scope.row.imageUrl" alt="" width="100%">
                          </div>
                          <div style="text-align:left">
                             <p>SKU:&nbsp;&nbsp;{{scope.row.asin}}</p>
                             <p>子ASIN:&nbsp;&nbsp;{{scope.row.commoditySku}}</p>
                          </div>
                        </div>
                     </el-popover>
                  </template>
               </el-table-column>
               <el-table-column label="商品数量" prop="quantity" align="center"></el-table-column>
               <!-- <el-table-column label="促销信息" prop="promotion" align="center"></el-table-column> -->
               <el-table-column label="销售额(¥)" prop="salePrice" align="center"></el-table-column>
               <el-table-column label="订单成本(¥)" prop="productFee" align="center"></el-table-column>
               <el-table-column label="FBA费用(¥)" prop="fbaFee" align="center"></el-table-column>
               <el-table-column label="亚马逊佣金(¥)" prop="referralFee" align="center"></el-table-column>
               <el-table-column label="促销费(¥)" prop="promotionFee" align="center"></el-table-column>
               <el-table-column label="亚马逊其他费用(¥)" prop="otherFee" align="center" width="170px"></el-table-column>
               <el-table-column label="系统设置商品成本(¥)" prop="localFee" align="center" width="180px"></el-table-column>
            </el-table>
           <div slot="footer" class="dialog-footer">
             <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
           </div>
         </el-dialog>
         <!-- 编辑 -->
         <el-dialog
           width="30%"
           title="订单备注"
           :visible.sync="orderTipsVisible"
          >
            <el-form :model="orderForm" ref="ruleForm" label-width="80px">
               <el-form-item label="订单编号">
                 <el-input v-model="orderForm.orderNumber" readonly="true" style="width:100%"></el-input>
               </el-form-item>
               <el-form-item label="测评订单">
                 <el-select 
                   clearable
                   v-model="orderForm.test" 
                   placeholder="请选择测试订单" 
                   style="width:100%"
                  >
                   <el-option
                    v-for="item in viewTypeList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                   </el-option>
                 </el-select>
               </el-form-item>
               <el-form-item label="订单备注">
                  <el-input
                    type="textarea" 
                    v-model="orderForm.orderTips" 
                    placeholder="订单备注"
                  ></el-input>
               </el-form-item>
            </el-form>
             <div slot="footer" class="dialog-footer">
                 <el-button @click="orderTipsVisible = false">取消</el-button>
                 <el-button type="primary" @click="submitForm()">确定</el-button>
               </div>
         </el-dialog>
       </div>
     </div>
   </div>
</template>

<script>
import ShopsAndSites from "@/components/ShopsAndSites.vue";
import {
   pageInit,  
   getListPage,
   getShowDetail,
   updateInit,  // 编辑初始化
   confirmEdit,
}from '@/api/orderList.js'
export default {
   components: {
     ShopsAndSites,
   },
   data() {
     return {
       loading: false,
       total: 0,
       pageInfoList: {
         amazonOrderNumber:'',
         orderType: "",
         isTest: "",
         dispatchType: "",
         current: 1,
         pageSize: 20
       },
       orderForm: {
         orderNumber: "",
         test: "",
         orderTips: ""
       },
       tress: [],
       orderTypeList: [],    // 订单类型
       viewTypeList: [],
       dispatchTypeList: [],
       subAsinList: [],
       tableDataList: [],
       timeRange: [],  // 日期时间范围
       keyword: "",
       select_Asin: "",
       shopId: "",
       marketPlaceId: "",
       orderDataList: [],
       dialogVisible: false,
       orderTipsVisible: false,
       showOrderDetail: {},
       currentEditId: "",
     }
   },
   created() {
      this.getPageInit();
   },
   computed: {
     getCurrentDate(){
      var mydate = new Date();
      var str = "" + mydate.getFullYear() + "-";
      //判断小于是是直接小于10月就可以了
      if(mydate.getMonth()<10 && mydate.getMonth() == 10){
          str +="0"+ (mydate.getMonth() + 1) + "-";
      }else{
          str += (mydate.getMonth() + 1) + "-";
      }
      //判断小于是是直接小于10日就可以了
      if(mydate.getDate()<10 && mydate.getMonth() == 10){
          str += "0"+mydate.getDate() + "";
      }else{
          str += mydate.getDate() + "";
      }
      str += mydate.getHours() + ":";
      str += mydate.getMinutes() + ":";
      str += mydate.getSeconds();
      return str;
    }
   },
   methods: {
      getPageInit() {
         pageInit()
         .then(res=> {
            var result = res.data.data;
            if(res.data.code == 200) {
              this.tress = result.tress;
              this.dispatchTypeList = result.fulTypeList;
              this.orderTypeList = result.orderStateList;
              this.viewTypeList = result.testTypeList;
              this.subAsinList = result.searchList;
            }
         })
      },
      // 查询事件
      search() {
        this.pageInfoList.current = 1;
        this.pageInfoList.pageSize = 20;
        this.tableSearch();
      },
      tableSearch() {
         let Sku_Asin = "";
         if(this.select_Asin == "" || this.keyword == "") {
            Sku_Asin = null;
         }else if(this.select_Asin == "asin") {
            Sku_Asin = { asin:this.keyword };
         }else if(this.select_Asin == "parent_asin") {
            Sku_Asin = { parent_asin:this.keyword };
         }else if(this.select_Asin == "commodity_sku") {
            Sku_Asin = { commodity_sku:this.keyword };
         }
         let params = {
            shopId: this.shopId,
            marketPlaceId: this.marketPlaceId,
            startDate: Array.isArray(this.timeRange)? this.timeRange[0]: "",
            endDate: Array.isArray(this.timeRange)? this.timeRange[1]: "",
            orderStatus: this.pageInfoList.orderType,
            current: this.pageInfoList.current,
            pageSize: this.pageInfoList.pageSize,
            fulType: this.pageInfoList.dispatchType,
            test: this.pageInfoList.isTest,
            amazonOrderNumber:this.pageInfoList.amazonOrderNumber
         }
         this.loading = true;
         getListPage({...Sku_Asin, ...params})
         .then(res=> {
            if(res.status == 200) {
              this.tableDataList = res.data.values;
              this.total = res.data.pageInfo.total;
              this.loading = false;
            }else if(res.status == 500) {
              this.$message.error(res.data.message);
            }
         })
      },
      handleSizeChange(newSize) {
         this.pageInfoList.pageSize = newSize;
         this.tableSearch();
      },
      handleCurrentChange(newCurrent) {
        this.pageInfoList.current = newCurrent;
        this.tableSearch();
      },
      clearAsin() {
        this.keyword = "";
        this.select_Asin = "";
      },
      getID(shopID, marketPlaceId) {
        this.shopId = shopID;
        this.marketPlaceId = marketPlaceId;
        this.tableSearch();
      },
      // 编辑初始化
      onEditBtn(row) {
         this.currentEditId = row.id;
         updateInit(row.id)
         .then(res=> {
            if(res.data.code == 200) {
              this.orderForm.orderTips = res.data.data.tips;
              this.orderForm.test = res.data.data.test;
            }
         })
         this.orderForm.orderNumber = row.orderNumber;
         this.orderTipsVisible = true;
      },
      submitForm() {
         let data = {
           id: this.currentEditId,
           test: this.orderForm.test,
           tips: this.orderForm.orderTips
         }
         confirmEdit(data)
         .then(res=> {
           if(res.data.code == 200) {  
              this.$message.success(res.data.message);
              this.orderTipsVisible = false;
              this.tableSearch();
           }else if(res.data.code == 500) {
              this.$message.error(res.data.message);
           }   
         })
      },
      // 详情
      onShowDetail(row) {
         getShowDetail(row.id)
         .then(res=> {
            this.showOrderDetail = {};
            this.orderDataList = [];
            if(res.data.code == 200) {
               this.showOrderDetail = res.data.data;
               this.orderDataList = res.data.data.itemDetailList;
            }else if(res.data.code == 500) {
               this.$message.error(res.data.message)
            }
         })
         this.dialogVisible = true;
      }
   }
}
</script>

<style lang="scss" scoped>
.storage {
  .append {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    .seach {
       width: 100%;
      .el-input {
        width: 230px;
        margin-right: 20px;
      }
      .el-table {
          margin-top: 30px;
      }
    }
  }
}
.Moder_pages {
   margin-top: 30px;
   text-align: center;
   margin-bottom: 10px;
}
.goodsInfo{
    display: flex;
    >div:nth-of-type(2){
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    p{
        font-size: 12px;
        color: #909399;
    }
}
.pic{
    width:150px;
    height: 150px;
    font-size:8px;
    color: #909399;
    text-align: center;
    line-height: 150px;
    margin-right: 10px;
    span{
        display: block;
        background: #EBEEF5;
        width: 100%;
        height: 100%;
    }
}
.noPic{
   width: 60px;
   height: 60px;
   text-align: center;
   line-height: 60px;
   font-size:8px;
   color: #909399;
   margin-right: 15px;
   span{
     display: block;
     background: #EBEEF5;
     width: 100%;
     height: 100%;
   }
}
.suspend{
   p{
     span{
      display: inline-block;
      width: 140px;
      margin-right: 10px;
      font-size: 13px;
     }
   }
}
img{
  vertical-align: middle;
}
</style>